import React from "react";
import TestimonialsAvatars from "./TestimonialsAvatars";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ArrowRight } from "lucide-react";
import { motion } from "framer-motion";
import Button from "./atoms/Button";
import ReviewSitesInlineRatings from "./ReviewSitesInlineRatings";
import ContactForm from "./ContactForm"; 
import { Border } from "./Border";
const HeroFullBG = ({ heroContent }) => {
  heroContent = heroContent[0];

  const {
    heroTitle,
    serviceTitle,
    heroImage,
    description,
    primaryActionBtnLabel,
    primaryActionBtnLink,
    style,
    alt,
    preloadImage,
    hideImage,
  } = heroContent;

  let list = description && description.description.split("-");
  let nonListSentence = list[0];
  list.splice(0, 1);
  for (let i = 0; i < list.length; i++) {
    if (list[i].charAt(0) === " ") {
      list[i] = list[i].slice(1, list[i].length);
    }
  }

  const wrapTextWithSpan = (inputStr) => {
    const regex = /\*(.*?)\*/g;
    const replacedStr = inputStr.replace(regex, '<span class="text-brand-600">$1</span>');
    return replacedStr;
  };

  return (
    <div className="relative min-h-[50dvh] shadow-sm pt-16 lg:pt-8 overflow-hidden md:mx-8 md:rounded-2xl ">
     
         {/* <div
        className="absolute top-0 left-0 w-full h-full z-[4] bg-[linear-gradient(125deg,rgba(249,253,255,.63)_60%,transparent_60%)]"/>
      <div
        className="absolute top-0 left-0 w-full h-full z-[4] bg-[linear-gradient(149deg,rgba(255,255,255,.75)_40%,transparent_40%)]
        md:bg-[linear-gradient(59deg,rgba(255,255,255,.7)_55%,transparent_55%)]"/> */}

<div
        className="absolute top-0 left-0 w-full h-full z-[4] bg-[linear-gradient(170deg,rgba(10,10,10,.75),transparent)] md:bg-[linear-gradient(45deg,rgba(10,10,10),transparent)]"/>
 
      <GatsbyImage
        image={getImage(heroImage)}
        alt={alt}
        loading={preloadImage}
        className="absolute top-0 left-0 w-full max-w-none h-full absolute-important h-full z-1 object-cover img-bg-hero-scale scale-1"
      />

      {/* <Image src="/images/lp-siding.jpeg" alt="me" width="500" height="500"  className="absolute top-0 left-0 w-full h-full z-0 object-cover" style={{ clipPath: 'polygon(60% 0, 100% 0%, 100% 100%, 50% 100%)'}}/> */}

      <section className="z-[10] relative max-w-7xl mx-auto flex flex-col lg:flex-row items-center justify-center gap-16 lg:gap-20  px-4 md:px-6 py-4 py-8 lg:py-16">
        <div className="lg:w-3/5 flex flex-col gap-4 lg:gap-8 items-center justify-center text-center lg:text-left lg:items-start lg:pr-12 md:py-8">
          {/* <div>
            <ReviewSitesInlineRatings invert={true} /> 
            </div> */}
          <motion.div
            initial={{ opacity: 0, y: 7 }}
            whileInView={{ opacity: 1, y: 0, transition: { duration: 0.35 } }}
            staggerChildren={0.8}
            viewport={{ once: true }}
          >
           
            <h1
              className="text-shadow-default font-extrabold text-white text-4xl lg:text-6xl tracking-tight mb-4 text-balance"
              dangerouslySetInnerHTML={{ __html: wrapTextWithSpan(heroTitle) }}
            />

            <p className=" text-xl text-gray-200 font-normal mb-6 pl-1">{nonListSentence}</p>
<div>
            {list.map((item, i) => (
              <Border top invert>
              <span key={i} className="py-4 flex text-white font-semibold  pl-1">
                <svg
                  className="h-6 w-6 p-1 bg-green-600 rounded-xl"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>

                <span className={`ml-2 md:text-lg font-medium text-white text-left`}>{item}</span>
              </span></Border>
            ))}
            </div>
            <div className="my-10">
              <Button color="brandGradient" size="xl" to='/contact/' className="w-full md:w-auto">
                Get a FREE Estimate <ArrowRight size={18} />
              </Button>
            </div>
             <TestimonialsAvatars priority={true}   className="text-white font-display text-sm" />

          </motion.div>
        </div>
        <div className="w-full lg:w-2/5 relative">
         
          <div className="shadow-2xl rounded-xl p-8 bg-white relative z-1 relative ">
            {/* <div
              style={{ transform: "rotate(10deg)", top: "-12px" }}
              class="special-tag rounded-r-none absolute bg-orange-500 font-display font-semibold inline-flex items-center mb-2 px-3 py-2 right-0 rounded-lg uppercase text-sm text-white"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="text-white w-5 h-5 mr-1">
                <path
                  fill="currentColor"
                  d="M48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L410.7 218.7C435.7 243.7 435.7 284.3 410.7 309.3L277.3 442.7C252.3 467.7 211.7 467.7 186.7 442.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5V80C0 53.49 21.49 32 48 32L48 32zM112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176z"
                />
                <path
                  fill="currentColor"
                  className="opacity-50"
                  d="M311.1 38.93C320.5 29.61 335.7 29.7 344.1 39.13L472.8 168.4C525.1 221.4 525.1 306.6 472.8 359.6L360.8 472.9C351.5 482.3 336.3 482.4 326.9 473.1C317.4 463.8 317.4 448.6 326.7 439.1L438.6 325.9C472.5 291.6 472.5 236.4 438.6 202.1L310.9 72.87C301.5 63.44 301.6 48.25 311.1 38.93V38.93z"
                />
              </svg>
              <span className="font-display text-xs">Online Special</span>
            </div> */}
            <h2 className="font-bold font-display text-2xl mb-1">Schedule A FREE Estimate</h2>
            <p className="text-gray-500 text-sm">Request a No-Obligation Estimate & Expert Advice!</p>
         
         <div className="my-4"> <ReviewSitesInlineRatings /></div> 
           
            {
            /* <div className="py-2 border-t border-neutral-100 pt-4 mt-4">
             
              
              <TestimonialsAvatars priority={true} inline className="text-white font-display text-sm" />
            </div> */}
                       

            <div className=" pt-4">
              <ContactForm showPlaceholders={true}  hideLabels={true} stacked={false}/>{" "}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroFullBG;
