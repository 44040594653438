import React from "react";
import google from "../img/logos/google.png";
import yelp from "../img/logos/yelp.png";
import fb from "../img/logos/fb.png";
import angies from "../img/logos/angies.png";
import bbb from "../img/logos/bbb.png";
import houzz from "../img/logos/houzz.png";
import guildquality from "../img/logos/guildquality.png";
import gaf from "../assets/gaf-c-p.svg";
import preffered from "../assets/preffered.svg"; 
export const GAFCERT = () => { 
  return  (
    <div className="text-center border-r  col-span-3 rounded-xl py-6 px-6 flex flex-col  items-center">
              <div className="h-[60px] flex items-center flex-shrink-0">
                <img src={gaf} alt="GAF Reviews" className="mb-0 mx-auto  mt-2 h-full" style={{ maxHeight: "65px" }} />
              </div>
              <div className="px-6 max-w-lg mx-auto">
                <span className="block text-neutral-700 font-bold text-lg my-2 mt-4">
                  GAF Certified Plus
                </span>
                <span className="block text-neutral-500 text-sm mt-1">
                 GAF's factory certification program that provides assurance to you that
                  the roof will be completed well and professionally.{" "}
                  <a
                    href="https://www.gaf.com/en-us/roofing-contractors/residential/usa/ga/jefferson/top-tier-roofing-1133406"
                    target="_blank"
                    rel="noreferrer"
                    className="text-brand-600 text-sm font-bold mt-2  hidden"
                  >
                    View Our Profile
                  </a>
                </span>
              </div>
            </div>
  )}

  export const OCCERT = () => { return (
    <div className="text-center col-span-3 rounded-xl py-6  px-6 flex flex-col  items-center">
    <div className="h-[60px] flex items-center flex-shrink-0">
      {" "}
      <img
        src={preffered}
        alt="Yelp Reviews"
        className="mb-0 mx-auto  mt-2  h-full"
        style={{ maxHeight: "50px" }}
      />
    </div>
    <div className="px-6 max-w-lg mx-auto">
      <span className="block text-neutral-700 font-bold text-base my-2 mt-4">
        Owens Corning Roofing Preferred Contractor
      </span>{" "}
      <span className="block text-neutral-400 text-sm mt-1">
        An exclusive network of roofing professionals
        who meet high standards and strict requirements for professionalism and reliability.
      </span>
    </div>
  </div>
  )}

const Certifications = ({ data, twoThirds }) => {
  return (
    <section className="bg-gradient-to-b from-white  to-white relative shadow-sm py-4 px-4 font-display">
      <div className={`max-w-7xl flex flex-wrap py-3 mx-auto`}>
        <div className={`w-full flex items-center  `}>
          <div className="grid grid-cols-3 md:grid-cols-6 gap-3 md:gap-5 text-center w-full">
           <GAFCERT />
           <OCCERT/>

            
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certifications;
